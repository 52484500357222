.App {
  text-align: center;
  width:90%;
  margin: 0 0 0 5%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

 /* FLEXBOX IFRAMES AMAZON */
 .flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  }
.flex-item {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin:18px;
  padding:24px;
  }
  iframe {
    margin:15px;border:0px solid transparent;
  }
/* FINE IFRAMES AMAZON */

.App-link {
  color: #61dafb;
}
.link {
  font-weight: 600;
  color: #e28743;
}
ul{
  background-color: rgb(13, 0, 20);
  border-top: 1px solid rgb(13, 0, 20);
  border-right: 1px solid rgb(175, 100, 221);
  border-bottom: 1px solid rgb(175, 100, 221);
  border-left: 1px solid rgb(13, 0, 20);
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
li{
  margin: 10px;
  color: rgb(175, 100, 221);
}
.menu a, a:visited {
  font-weight: 400;
  color: #e28743;
  text-decoration:none;
}
.menu a:hover, a:active {
  font-weight: 600;
  color: #e28743;
  text-decoration:none;
}
.carda {  
  display: flex;
  flex-direction: row;
}
.quadrato300 {
  display:flex;
  align-items: center;
  justify-content: center;
}
.cardProdotto{
  text-align:center;
  padding: 1% 1% 3% 1%;
  border: 3px solid rgb(87, 0, 138);
  border-radius: 12px;
  background-color: #fff;
  color: #666;
}
.cardProdottoDett{
  text-align:center;
  padding: 2%;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  background-color: #f8f8f8;
  color: #666;
}
.cardProdotto a, a:active, a:visited, a:hover {
  font-weight: 600;
  color: #e28743;
  text-decoration:none;
}
.cardProdotto h4{
  min-height: 320px !important;
}
.cardProdottoDett a, a:active, a:visited, a:hover {
  font-weight: 600;
  color: #e28743;
  text-decoration:none;
}
.cardProdottoDett .copertina {
  width:100%;
  text-align:center;
  margin-bottom:2%;
}
.cardProdotto img {
  width:100%;
  height: 240px !important;
  max-width:300px !important;
  object-fit: contain;
  vertical-align: center;
}
.cardProdottoDett .fotoCorso{
  max-width:300px !important;
  border-radius: 12px;
  object-fit: contain;
}
.cardProdotto h3 {
  color: #a34db4;
}
.cardProdotto p {
  text-align:justify;
  margin: 4% 0 5% 0;
}
.cardProdottoDett h5 {
  font-weight:600;
  margin: 2% 0 0 0;
}
.titolone {
  min-height: 100px !important;
}
.cardProdottoDett .dettaglio {
  text-align:justify;
}
/* .cardProdotto h2{
  font-size: 1.4rem;
  text-align: right;
  margin-top: 1%;
  color:#e28743;
  background-color: transparent;
  padding: 4px;
} */
.uppercase{
  text-transform: uppercase;
}
.cardProdotto .prezzo{
  font-size: 1.2rem;
  text-align: right;
  margin-top: 1%;
  text-decoration: none;
  color:#e28743;
  background-color: transparent;
  padding: 4px;
}
.cardProdotto .offerta-undefined{
  font-size: 1.2rem;
  text-align: right;
  margin-top: 1%;
  text-decoration: none;
  color:#e28743;
  background-color: transparent;
  padding: 4px;
}
.cardProdotto .offerta-true{
  font-size: 1.2rem;
  text-align: right;
  margin-top: 1%;
  text-decoration: line-through;
  color:#e2874366;
  background-color: transparent;
  padding: 4px;
}
h6 {
  background-color:#57008a;
  color: #e28743;
  padding:2px 4px;
  border-radius: 6px;
  font-size:1.2rem;
}
.topoffer-true{
  font-size: 1.2rem;
  text-align: center;
  margin: 20px 0 20px 0;
  height: 40px;
  color:#e28743;
  background-color: rgb(87, 0, 138);
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #e28743;
}
.topoffer-undefined{
  text-decoration: line-through;
}
.txtoffer-undefined{
  display: none;
}
.txtoffer-true{
  display: inline;
  color: #57008a;
  text-transform: uppercase;
  font-size:0.8rem;
}
.txtoffer-true-nero{
  color:#fff;
  display: inline;
  font-size:1rem;
  float: left;
}
.viola{
  color: #fff;
  background-color:#57008a;
  padding: 4px;
  border-radius: 4px;
}